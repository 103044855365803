// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap"
import displayRSS from './frontend/rss'

document.addEventListener('DOMContentLoaded', () => {
  const signinBtn = document.getElementById('signin_btn')
  if (signinBtn) {
    signinBtn.addEventListener('click', (e) => {
      const form = document.getElementById('login-form');
      e.preventDefault();
      const email = form.email.value;
      const password = form.password.value;
      const remember = form.remember.checked;
      const tokenTag = document.querySelector('meta[name="csrf-token"]');
      fetch('/users/sign_in', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-CSRF-Token': tokenTag.content
        },
        body: JSON.stringify({
          user: {
            email: email,
            password: password,
            remember_me: remember
          }
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('ログインに失敗しました');
        }
        return response.json();
      })
      .then(data => {
        window.location.href = '/app/coaching';
      })
      .catch(error => {
        console.error(error.message);
      });
    });
  }
  displayRSS()
})