
const displayRSS = async () => {
  let items = await fetch('/rss')
    .then((response) => {
      if (!response.ok) {
        throw new Error('RSSの取得に失敗しました');
      }
      return response.json();
    });

  if (items.length > 2) {
    const blog1Title = document.getElementById("blog-1-title")  
    const blog1Text = document.getElementById("blog-1-text")  
    const blog1Link = document.getElementById("blog-1-link")  
    const blog1Img = document.getElementById("blog-1-img")  
    blog1Title.innerText = items[0].title
    blog1Text.innerHTML = items[0].description
    blog1Link.href = items[0].link
    blog1Img.src = items[0].thumbnail

    const blog2Title = document.getElementById("blog-2-title")  
    const blog2Text = document.getElementById("blog-2-text")  
    const blog2Link = document.getElementById("blog-2-link")  
    const blog2Img = document.getElementById("blog-2-img")  
    blog2Title.innerText = items[1].title
    blog2Text.innerHTML = items[1].description
    blog2Link.href = items[1].link
    blog2Img.src = items[1].thumbnail

    const blog3Title = document.getElementById("blog-3-title")  
    const blog3Text = document.getElementById("blog-3-text")  
    const blog3Link = document.getElementById("blog-3-link")  
    const blog3Img = document.getElementById("blog-3-img")  
    blog3Title.innerText = items[2].title
    blog3Text.innerHTML = items[2].description
    blog3Link.href = items[2].link
    blog3Img.src = items[2].thumbnail
  }
}

export default displayRSS